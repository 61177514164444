<template>
	<div>
		<div id="cashflowTicket" align="center" v-show="false">
			<table border="0" width="450">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">*****************************************</div>
						<div class="txt3">Sucursal {{ branchOffice?.branch_office_name }}</div>
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">Corte de caja - Azul</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td width="50%" align="center" class="txt2">
						<b>Fecha y hora</b>
						<br />
						{{ ticketData.created_at }} hrs.
					</td>
					<td width="50%" align="center" class="txt2">
						<b>Total</b>
						<br />
						<b>{{ numeral(totalPacific).format('$0,0.00') }}</b>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr>
					<td align="center" class="txt2">
						<b>=== Ingresos ===</b>
					</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(ticketData?.pacific?.cash.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de crédito:</b> {{ numeral(ticketData?.pacific?.credit.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de débito:</b> {{ numeral(ticketData?.pacific?.debit.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(ticketData?.pacific?.transfer.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(ticketData?.pacific?.paypal.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(ticketData?.pacific?.mercadopago.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Aplazo:</b> {{ numeral(ticketData?.pacific?.aplazo.total).format('$0,0.00') }}</td>
				</tr>
			</table>
			<table border="0" width="450" class="pt20">
				<tr>
					<td align="center">
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450" class="pt20" v-if="ticketData.showPanelAmount">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">Corte de caja - Morado</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td width="50%" align="center" class="txt2">
						<b>Fecha y hora</b>
						<br />
						{{ ticketData.created_at }} hrs.
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td align="center" class="txt2">
						<b>Total</b>
					</td>
				</tr>
				<tr>
					<td align="center" class="txt2">
						<b>{{ numeral(ticketData.cash).format('$0,0.00') }}</b>
					</td>
				</tr>
			</table>
			<table border="0" width="450" class="pt20" v-if="ticketData.showPanelAmount">
				<tr>
					<td align="center">
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">Corte de caja - Gris</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td width="50%" align="center" class="txt2">
						<b>Fecha y hora</b>
						<br />
						{{ ticketData.created_at }} hrs.
					</td>
					<td width="50%" align="center" class="txt2">
						<b>Total</b>
						<br />
						<b>{{ numeral(totalRLS).format('$0,0.00') }}</b>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr>
					<td align="center" class="txt2">
						<b>=== Ingresos ===</b>
					</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(ticketData.rls.cash.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de crédito:</b> {{ numeral(ticketData.rls.credit.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de débito:</b> {{ numeral(ticketData.rls.debit.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(ticketData.rls.transfer.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(ticketData.rls.paypal.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(ticketData.rls.mercadopago.total).format('$0,0.00') }}</td>
				</tr>
			</table>
			<table border="0" width="450" style="margin-top: 1.5cm">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">*****************************************</div>
						<div class="txt3">Resumen de depósitos</div>
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">{{ branchOffice.account_info_s2r }}</div>
						<div class="txt3">{{ numeral(ticketData?.pacific?.cash.total).format('$0,0.00') }}</div>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">{{ branchOffice.account_info_rls }}</div>
						<div class="txt3">{{ numeral(ticketData.rls.cash.total).format('$0,0.00') }}</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import numeral from 'numeral'
import VueHtmlToPaper from 'vue-html-to-paper'
import utilities from '@/services/utilities'
import moment from 'moment'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css', 'resources/css/printTickets.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'cashflowTicketComponent',
	computed: {
		...mapGetters('cashFlow', ['ticketData']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		...mapGetters('user', ['user']),
		branchOffice() {
			return this.branchOfficesList.find(e => e.id == this.user.branch_office_id)
		},
	},
	data() {
		return {
			ticketVisible: false,
			totalPacific: 0,
			totalRLS: 0,
		}
	},
	mounted() {
		if (this.branchOfficesList.length == 0) {
			this.$store.dispatch('branchOffices/GET')
		}
	},
	methods: {
		numeral,
		moment,
	},
	watch: {
		ticketData(newData) {
			if (utilities.objectValidate(newData, 'cash_flow_id', false)) {
				this.ticketVisible = true
				this.totalPacific = newData.pacific.credit.total + newData.pacific.debit.total + newData.pacific.mercadopago.total + newData.pacific.paypal.total + newData.pacific.transfer.total + newData.pacific.cash.total + newData.pacific.aplazo.total
				this.totalRLS = newData.rls.credit.total + newData.rls.debit.total + newData.rls.mercadopago.total + newData.rls.paypal.total + newData.rls.transfer.total + newData.rls.cash.total

				setTimeout(() => {
					this.$htmlToPaper('cashflowTicket')
				}, 500)

				setTimeout(() => {
					this.ticketVisible = false
				}, 3000)
			}
		},
	},
}
</script>
